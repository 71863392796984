/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-plusplus */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React, { useState, useRef } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useSortBy, useTable, usePagination } from 'react-table';
import { useSticky } from 'react-table-sticky';
import IcomoonReact from 'icomoon-react';
import moment from 'moment';

import iconSet from '../../../shared/images/teambuildr-selection.json';
import { fetchGroupAnalysisData, setActiveFilters, setRecoveryRadarView } from '../ducks/RecoveryRadarActions';
import { dashboardTracker } from '../../../shared/utils/amplitudeHelper';

const Styles = styled.div`
  font-family: nunito sans;
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  align-items: flex-start;
  overflow: hidden;
  margin-left: 5px;

  & .sticky {
    overflow-x: auto;
    margin: 10px 10px 25px;
    width: 100%;

    tfoot {
      td:first-child {
         position: sticky;
         left: 0;
      }
    }

    [data-sticky-td] {
      position: sticky;
    }
  }

  .athleteColumnHeaderCell {
    border-bottom: none;
    background: none;
  }

  .noHoverCategoryColumnTitleCell {
    background-color: #F0F0F0;
    color: #444444;
    font-size: 16px;
    font-weight: 700;
    border-right: 1px solid #BDBDBD1A;
  }

  .rawValueTitle {
    background-color: #F0F0F0;
    color: #444444;
    cursor: pointer;
    font-family: 'Nunito Sans';
    font-size: 16px;
    font-weight: 400;
    min-width: 130px;
    max-width: 130px;
    text-align: center;
  }

  .athleteNameTitle {
    background-color: #F0F0F0;
    border-top-left-radius: 8px;
    color: #444444;
    cursor: pointer;
    font-family: 'Nunito Sans';
    font-size: 16px;
    font-weight: 700;
    text-align: left;
    text-wrap: nowrap;
  }

  .footerCells {
    font-size: 16px;
    font-family: 'Nunito Sans';
    font-weight: 400;
    text-align: center;
    color: #444444;
  }

  .clickableText {
    cursor: pointer;
    text-decoration: underline;
    text-wrap: nowrap;
  }

  td:has(div):has(.percentRed) {
    background-color: #FFCDCD;
  }

  table {
    border-spacing: 0;
    border-collapse: separate;
    cursor: default;
    margin-right: 30px;
    table-layout: auto;
    width: 100%;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    
    tr:first-child {
      th:last-child {
        border-top-right-radius: 8px;
      }
    }

    th,
    td {
      margin: 0;
      background: white;
      padding: 15px;
      border-bottom: 1px solid #E0E0E0;
      border-right: 1px solid #BDBDBD1A;
      text-align: center;
    }

    tr:first-child {
      th:first-child {
        border-right: none;
      }
      th:nth-child(2) {
        border-top-left-radius: 8px;
      }
    }

    tfoot {
      tr:first-child {
        td {
         background: #F0F0F0;
         border-top: 1px solid #E0E0E0;
         height: 61px;
         min-height: 61px;
         border-bottom: none;
        }
        td:last-child {
          border-bottom-right-radius: 8px;
        }
      }
      td:first-child {
        border-bottom-left-radius: 8px;
        color: #444444;
        font-size: 16px;
        font-weight: 400;
        text-align: left;
        font-family: 'Nunito Sans';
      }
      tr:last-child {
        display: none;
      }
    }
  }
`;

const Table = ({
  columns,
  data,
  getCellProps,
  getHeaderProps,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    prepareRow,
    page,
  } = useTable({
    columns,
    data,
    initialState: { pageIndex: 0, pageSize: 100 },
  },
  useSticky,
  useSortBy,
  usePagination);

  return (
    <>
      <div id='reportTable' style={{ display: 'flex', width: '100%' }} className='sticky'>
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup, i) => (
              <tr {...headerGroup.getHeaderGroupProps()} data-a-wrap>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps([
                      {
                        className: column.className,
                      },
                      getHeaderProps(column),
                    ])}
                    data-cols-width='30'
                    data-a-wrap
                    data-f-bold
                    data-fill-color={column?.style?.fillColor || 'FF424242'}
                    data-b-a-s='thin'
                    data-b-a-c='FF000000'
                    data-f-color={column?.style?.fillColor ? 'FF000000;' : 'FFFFFFFF'}
                    data-a-h='center'
                    data-a-v='center'
                  >
                    {column.render('Header')}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? (
                            <IcomoonReact
                              iconSet={iconSet}
                              size={10}
                              color='black'
                              icon='down-arrow'
                              style={{ marginLeft: '5px' }}
                            />
                          )
                          : (
                            <IcomoonReact
                              iconSet={iconSet}
                              size={10}
                              color='black'
                              icon='up-arrow'
                              style={{ marginLeft: '5px' }}
                            />
                          )
                        : ''}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td
                      {...cell.getCellProps([
                        {
                          style: cell.column.style,
                        },
                        getCellProps(cell),

                      ])}
                      data-fill-color={getCellProps(cell)?.style?.fillColor || null}
                      data-f-color={getCellProps(cell)?.style?.fontColor}
                      data-b-a-s='thin'
                      data-b-a-c='FF000000'
                      data-a-wrap
                    >
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            {footerGroups.map((group) => (
              <tr {...group.getFooterGroupProps()}>
                {group.headers.map((column) => <td data-a-wrap {...column.getFooterProps([{ className: 'footerCells' }])}>{column.render('Footer')}</td>)}
              </tr>
            ))}
          </tfoot>
        </table>
      </div>
    </>
  );
};

const RROverviewTable = ({
  averageData,
  containerWidth,
  dataRows,
  rowIsScrollable,
  setColumnHeaderHeight,
}) => {
  const accountCode = useSelector((state) => state.auth.data.currentUser.accountCode);
  const activeTypeFilters = useSelector((state) => state.recoveryRadar.data.activeTypeFilters);
  const dateRange = useSelector((state) => state.recoveryRadar.data.dateRange);
  const group = useSelector((state) => state.recoveryRadar.data.group);
  const selectedDateData = useSelector((state) => state.recoveryRadar.data.selectedDateData);

  const formattedDate = moment(selectedDateData).format('MMM DD');
  const typeIds = activeTypeFilters.map((obj) => obj.id);

  const chartRef = useRef(null);
  const [headerAndSubHeaderHeight, setHeaderAndSubHeaderHeight] = useState(0);
  const [isScrollable, setIsScrollable] = useState(false);

  const handleScroll = () => {
    /** targetting the sticky container div scroll properties */
    const scrollWidth = chartRef.current?.childNodes[0]?.scrollWidth;
    const offsetWidth = chartRef.current?.childNodes[0]?.offsetWidth;
    const scrollLeft = chartRef.current?.childNodes[0]?.scrollLeft;
    setIsScrollable(offsetWidth + scrollLeft !== scrollWidth);
  };

  const dispatch = useDispatch();

  const handleClick = (bodyPartName, id) => {
    batch(() => {
      dispatch(setRecoveryRadarView('analysis'));
      dispatch(setActiveFilters([{ id, name: bodyPartName }]));
      dispatch(fetchGroupAnalysisData(
        accountCode,
        group?.id,
        moment(selectedDateData).subtract(27, 'd').format('YYYY-MM-DD'),
        selectedDateData,
        typeIds,
        [id],
      ));
    });
  };

  React.useEffect(() => {
    if (chartRef.current) {
      const scrollWidth = chartRef.current.childNodes[0]?.scrollWidth;
      const offsetWidth = chartRef.current.childNodes[0]?.offsetWidth;
      const scrollLeft = chartRef.current.childNodes[0]?.scrollLeft;
      setIsScrollable(offsetWidth + scrollLeft !== scrollWidth);
    }
  }, []);

  React.useEffect(() => {
    if (chartRef.current) {
      setHeaderAndSubHeaderHeight(
        chartRef.current.childNodes[0].childNodes[0].childNodes[0].clientHeight,
      );
      setColumnHeaderHeight(
        chartRef.current.childNodes[0].childNodes[0].childNodes[0].childNodes[0].clientHeight,
      );
    }
  }, []);

  const athleteNameColumn = [
    {
      Header: '',
      Footer: () => null,
      id: 'name',
      sticky: 'left',
      className: 'athleteColumnHeaderCell',
      columns: [
        {
          Header: 'Area Selected',
          accessor: 'bodyPartName',
          Footer: 'Group Average',
          className: 'athleteNameTitle',
          style: {
            /** style for the whole column
             * className is style for just the header
            */
            borderRight: '1px solid #E0E0E0',
            height: '58px',
            textAlign: 'left',
          },
          Cell: ({ row }) => (
            <text
              className='clickableText'
              onClick={() => {
                handleClick(row.original.bodyPartName, row.original.bodyPartId);
                // Amplitude tracking
                dashboardTracker('Recovery Radar', 'Overview - Table', 'Area selected clicked. User taken to group analysis screen with this body part selected in filter');
              }}
            >
              {row.original.bodyPartName ?? '-'}
            </text>
          ),
        },
      ],
    },
  ];

  const DataColumns = [
    {
      Header: 'Reported',
      Footer: () => null,
      id: 'reportedTitle',
      className: 'noHoverCategoryColumnTitleCell',
      columns: [
        {
          Header: formattedDate,
          Footer: () => averageData.dailyAverageReports ?? '-',
          accessor: (row) => row.dailyCount,
          Cell: ({ row }) => (
            <div>
              {row.original.dailyCount ?? '-'}
            </div>
          ),
          id: 'selectedDate1',
          className: 'rawValueTitle',
          style: {
            fontColor: '#444444',
            fontSize: '16px',
            fontWeight: 400,
            fillColor: 'FFBDBDBD1A',
            flexGrow: 1,
          },
        },
        {
          Header: `${dateRange} Day Avg.`,
          accessor: (row) => row.previousAverageReports,
          Cell: ({ row }) => (
            <div>
              {row.original.previousAverageReports ?? '-'}
            </div>
          ),
          Footer: () => averageData.previousAverageReports ?? '-',
          id: 'sevenDayAvg1',
          className: 'rawValueTitle',
          style: {
            fontColor: '#444444',
            fontSize: '16px',
            fontWeight: 400,
            fillColor: 'FFBDBDBD1A',
            flexGrow: 1,
          },
        },
        {
          Header: 'Change',
          accessor: (row) => row.reportsPercentChange,
          Cell: ({ row }) => {
            if (row.original.isReportsPercentChangePositive) {
              return (
                <div className='percentRed'>
                  {`${row.original.reportsPercentChange}%`}
                </div>
              );
            }
            return (
              <div>
                {row.original.reportsPercentChange !== null && row.original.reportsPercentChange !== undefined ? `${row.original.reportsPercentChange}%` : '-'}
              </div>
            );
          },
          Footer: () => (averageData.reportsPercentChange !== null && averageData.reportsPercentChange !== undefined ? `${averageData.reportsPercentChange}%` : '-'),
          id: 'change1',
          className: 'rawValueTitle',
          style: {
            fontColor: '#444444',
            fontSize: '16px',
            fontWeight: 400,
            fillColor: 'FFBDBDBD1A',
            flexGrow: 1,
          },
          sortType: (rowA, rowB, columnId, desc) => {
            const a = rowA.values[columnId];
            const b = rowB.values[columnId];

            if (a === null && b === null) return 0;
            if (a === null) return desc ? -1 : 1;
            if (b === null) return desc ? 1 : -1;

            if (a < b) return -1;
            if (a > b) return 1;
            return 0;
          },
        },
      ],
    },
    {
      Header: 'Avg. Severity',
      Footer: () => null,
      id: 'avgSeverity',
      className: 'noHoverCategoryColumnTitleCell',
      columns: [
        {
          Header: formattedDate,
          Footer: () => averageData.dailyAverageSeverity ?? '-',
          accessor: (row) => row.dailyAverageSeverity,
          Cell: ({ row }) => (
            <div>
              {row.original.dailyAverageSeverity ?? '-'}
            </div>
          ),
          id: 'selectedDate2',
          className: 'rawValueTitle',
          style: {
            fontColor: '#444444',
            fontSize: '16px',
            fontWeight: 400,
            fillColor: 'FFBDBDBD1A',
            flexGrow: 1,
          },
        },
        {
          Header: `${dateRange} Day Avg.`,
          accessor: (row) => row.previousAverageSeverity,
          Cell: ({ row }) => (
            <div>
              {row.original.previousAverageSeverity ?? '-'}
            </div>
          ),
          Footer: () => averageData.previousAverageSeverity ?? '-',
          id: 'sevenDayAvg2',
          className: 'rawValueTitle',
          style: {
            fontColor: '#444444',
            fontSize: '16px',
            fontWeight: 400,
            fillColor: 'FFBDBDBD1A',
            flexGrow: 1,
          },
        },
        {
          Header: 'Change',
          accessor: (row) => row.severityPercentChange,
          Cell: ({ row }) => {
            if (row.original.isSeverityPercentChangePositive) {
              return (
                <div className='percentRed'>
                  {`${row.original.severityPercentChange}%`}
                </div>
              );
            }
            return (
              <div>
                {row.original.severityPercentChange !== null && row.original.severityPercentChange !== undefined ? `${row.original.severityPercentChange}%` : '-'}
              </div>
            );
          },
          Footer: () => (averageData.severityPercentChange !== null && averageData.severityPercentChange !== undefined ? `${averageData.severityPercentChange}%` : '-'),
          id: 'change2',
          className: 'rawValueTitle',
          style: {
            fontColor: '#444444',
            fontSize: '16px',
            fontWeight: 400,
            fillColor: 'FFBDBDBD1A',
            flexGrow: 1,
          },
          sortType: (rowA, rowB, columnId, desc) => {
            const a = rowA.values[columnId];
            const b = rowB.values[columnId];

            if (a === null && b === null) return 0;
            if (a === null) return desc ? -1 : 1;
            if (b === null) return desc ? 1 : -1;

            if (a < b) return -1;
            if (a > b) return 1;
            return 0;
          },
        },
      ],
    },
  ];

  const columns = [...athleteNameColumn, ...DataColumns];

  const memoizedColumns = React.useMemo(
    () => columns,
    [dataRows, containerWidth],
  );
  const memoizedDataRows = React.useMemo(
    () => dataRows,
    [dataRows, containerWidth],
  );

  const handleHeaderClick = (headerInfo) => {
    if (headerInfo.Header !== ('' || 'Reported' || 'Avg. Severity')) {
      headerInfo.toggleSortBy();
    }
  };

  return (
    <>
      <Styles
        ref={chartRef}
        onScroll={handleScroll}
        isScrollable={isScrollable}
        rowIsScrollable={rowIsScrollable}
        headerAndSubHeaderHeight={headerAndSubHeaderHeight}
        containerWidth={containerWidth}
      >
        <Table
          containerWidth={containerWidth}
          columns={memoizedColumns}
          data={memoizedDataRows}
          getCellProps={() => ({
            style: {
              backgroundColor: 'FFFFFFFF',
              color: 'FF444444',
              fillColor: 'FFFFFFFF',
              fontColor: 'FF444444',
            },
          })}
          getHeaderProps={(headerInfo) => ({
            onClick: () => handleHeaderClick(headerInfo),
          })}
        />
      </Styles>
    </>
  );
};

export default RROverviewTable;
